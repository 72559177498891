export class Organization {
  id: string;
  name: string;
  description: string;
  url: string;

  constructor(values) {
    Object.assign(this, values);
  }

}
