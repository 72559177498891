import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Organization} from '../models/organization';
import {environment} from '../../../environments/environment';
import {map, flatMap} from 'rxjs/operators';
import {InVIDUser} from '../models/invid-user';
import {concat, forkJoin, merge, Observable} from 'rxjs/index';
import {UGV} from '../models/ugv';
import {YouTubeVideo} from '../models/youtubevideo';
import {FacebookVideo} from '../models/facebookvideos';
import {TwitterVideo} from '../models/twittervideos';
import {ExternalUser} from '../models/externaluser';
import {ReuseRequest} from '../models/reuserequest';
import {mergeAll} from 'rxjs/internal/operators';


@Injectable()
export class UgvService {
  constructor(
    private http: HttpClient
  ) {}

  getUGVs() {
    const url = environment.api + '/uGVs';
    return this.http.get(url).pipe(
      map (response => {
        const youtubeVideos: YouTubeVideo[] = response['_embedded']['youTubeVideos'] ? response['_embedded']['youTubeVideos'].map(v => new YouTubeVideo(v)) : [];
        const facebookVideos: FacebookVideo[] = response['_embedded']['facebookVideos'] ? response['_embedded']['facebookVideos'].map(v => new FacebookVideo(v)) : [];
        const twitterVideos: TwitterVideo[] = response['_embedded']['twitterVideos'] ? response['_embedded']['twitterVideos'].map(v => new TwitterVideo(v)) : [];

        return [...youtubeVideos, ...facebookVideos, ...twitterVideos]}),
      flatMap( (ugvs: UGV[]) => {
        const ugvs$: Observable<UGV>[] = ugvs.map(ugv => this.fullUGV(ugv));
        return forkJoin(...ugvs$);
      })
    );
  }

  remove(id: string) {
    const url = environment.api + '/uGVs/' + id;
    return this.http.delete(url);
  }

  getUGVRequests(ugvId: string) {
    const url = environment.api + '/uGVs/' + ugvId + '/reuseRequests';
    return this.http.get(url).pipe(
      map(response => {
        return response['_embedded']['reuseRequests'] ? response['_embedded']['reuseRequests'].map(r => new ReuseRequest(r)) : []
      }),
      flatMap((requests: ReuseRequest[]) => forkJoin(...requests.map(request => this.fullRequest(request))))
    )
  }

  private fullUGV(ugv: UGV): Observable<UGV> {
    const submittersUrl = `${environment.api}/uGVs/${ugv.id}/submitters`;
    const submitters$ = this.http.get(submittersUrl).pipe(
      map (response => response['_embedded']['inVIDUsers'] ? response['_embedded']['inVIDUsers'].map(u => new InVIDUser(u)) : [] ),
      map ((subs: InVIDUser[]) => {
        ugv.submitters = subs;
        return ugv;
      })
    );
    const userUrl = `${environment.api}/uGVs/${ugv.id}/user`;
    const user$ = this.http.get<ExternalUser>(userUrl).pipe(
      map ((e: ExternalUser) => {
        ugv.user = e;
        return ugv;
      } )
    );
    const requestsCountUrl = `${environment.api}/reuseRequests/search/countByAboutVideoId?id=${ugv.id}`;
    const requests$ = this.http.get(requestsCountUrl).pipe(
      map (response => {
        ugv.reuseRequestsCount = +response;
        return ugv;
      })
    );
    return merge(user$, submitters$, requests$);
  }

  private fullRequest(request: ReuseRequest) {
    const createdByUrl = `${environment.api}/reuseRequests/${request.id}/createdBy`;
    const createdBy$ = this.http.get(createdByUrl).pipe(
      map (response => {
        request.createdBy = new InVIDUser(response);
        return request;
      })
    );

    const aboutVideoUrl = `${environment.api}/reuseRequests/${request.id}/aboutVideo`;
    const aboutVideo$ = this.http.get(aboutVideoUrl).pipe(
      map (response => {
        request.aboutVideo = new UGV(response);
        return request;
      })
    );
    return merge(createdBy$, aboutVideo$);
  }
}
