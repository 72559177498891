import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Organization} from '../models/organization';
import {environment} from '../../../environments/environment';
import {map, flatMap} from 'rxjs/operators';
import {InVIDUser} from '../models/invid-user';
import {concat, forkJoin, merge, Observable} from 'rxjs/index';
import {UGV} from '../models/ugv';
import {YouTubeVideo} from '../models/youtubevideo';
import {FacebookVideo} from '../models/facebookvideos';
import {TwitterVideo} from '../models/twittervideos';
import {ExternalUser} from '../models/externaluser';
import {ReuseRequest} from '../models/reuserequest';
import {mergeAll} from 'rxjs/internal/operators';


@Injectable()
export class RequestService {
  constructor(
    private http: HttpClient
  ) {}

  remove(id: String) {
    const url = environment.api + '/reuseRequests/' + id;
    return this.http.delete(url);
  }

  getRequestsByUGVId(ugvId: String) {
    const url = environment.api + '/uGVs/' + ugvId + '/reuseRequests';
    return this.http.get(url).pipe(
      map(response => {
        return response['_embedded']['reuseRequests'] ? response['_embedded']['reuseRequests'].map(r => new ReuseRequest(r)) : []
      }),
      flatMap((requests: ReuseRequest[]) => forkJoin(...requests.map(request => this.fullRequest(request))))
    );
  }

  private fullRequest(request: ReuseRequest) {
    const createdByUrl = `${environment.api}/reuseRequests/${request.id}/createdBy`;
    const createdBy$ = this.http.get(createdByUrl).pipe(
      map (response => {
        request.createdBy = new InVIDUser(response);
        return request;
      })
    );

    const aboutVideoUrl = `${environment.api}/reuseRequests/${request.id}/aboutVideo`;
    const aboutVideo$ = this.http.get(aboutVideoUrl).pipe(
      map (response => {
        request.aboutVideo = new UGV(response);
        return request;
      })
    );
    return merge(createdBy$, aboutVideo$);
  }
}
