import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {SigninComponent} from './auth/signin/signin.component';
import {AuthService} from './auth/auth.service';
import {RouterModule} from '@angular/router';
import {routes} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppNavbarComponent } from './app-navbar/app-navbar.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { UsersComponent } from './users/users.component';
import { UsersDisabledComponent } from './users/users-disabled/users-disabled.component';
import {TokenInterceptor} from './auth/token.interceptor';
import {InVIDUserService} from './shared/services/invid-user.service';
import {AvatarModule} from 'ngx-avatar';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { OrganizationsComponent } from './organizations/organizations.component';
import {OrganizationService} from './shared/services/organization.service';
import { UgvComponent } from './ugv/ugv.component';
import {UgvService} from './shared/services/ugv.service';
import { UsersListComponent } from './users/users-list/users-list.component';
import {UgvRequestsComponent} from './ugv/requests/ugvrequests.component';
import {RequestService} from './shared/services/request.service';
import { ContentownerComponent } from './contentowner/contentowner.component';
import {ContentOwnerService} from './shared/services/content-owner.service';
import {faFacebookSquare, faGoogle, faTwitterSquare, faYoutube} from '@fortawesome/free-brands-svg-icons';


@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    AppNavbarComponent,
    UsersComponent,
    UsersDisabledComponent,
    OrganizationsComponent,
    UgvComponent,
    UsersListComponent,
    UgvRequestsComponent,
    ContentownerComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    ReactiveFormsModule,
    NgbModule.forRoot(),
    AvatarModule,
    FontAwesomeModule,
    FormsModule
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    InVIDUserService,
    OrganizationService,
    UgvService,
    RequestService,
    ContentOwnerService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
