import { Component, OnInit } from '@angular/core';
import {UgvService} from '../shared/services/ugv.service';
import {UGV} from '../shared/models/ugv';
import {YouTubeVideo} from '../shared/models/youtubevideo';
import {TwitterVideo} from '../shared/models/twittervideos';
import {FacebookVideo} from '../shared/models/facebookvideos';
import {Router} from '@angular/router';
import {faCopyright, faTrash} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-ugv',
  templateUrl: './ugv.component.html',
  styleUrls: ['./ugv.component.css']
})
export class UgvComponent implements OnInit {
  faTrash = faTrash;
  faCopyright = faCopyright;
  ugvs: UGV[];

  constructor(
    private ugvService: UgvService,
    private router: Router
  ) { }

  ngOnInit() {
    this.ugvService.getUGVs().subscribe(
        ugvs => this.ugvs = ugvs,
        err => alert('Something went wrong: ' + err)
      );
  }

  getSocialNetworkClass(ugv: UGV): string {
    if(ugv instanceof YouTubeVideo)
      return 'table-danger';
    else if( ugv instanceof TwitterVideo)
      return 'table-info';
    else if(ugv instanceof FacebookVideo)
      return 'table-primary';
  }

  removeUGV(id: string) {
    this.ugvService.remove(id).subscribe(
      () => this.ugvs = this.ugvs.filter(value => value.id !== id),
      err => alert('Something went wrong: ' + err)
    );
  }

  formatSubmitters(ugv: UGV): string {
    return ugv.submitters.map(s => s.email).join(', ');
  }

}
