import {Routes} from '@angular/router';
import {SigninComponent} from './auth/signin/signin.component';
import {UsersDisabledComponent} from './users/users-disabled/users-disabled.component';
import {OrganizationsComponent} from './organizations/organizations.component';
import {UgvComponent} from './ugv/ugv.component';
import {UsersListComponent} from './users/users-list/users-list.component';
import {UgvRequestsComponent} from './ugv/requests/ugvrequests.component';
import {ContentownerComponent} from './contentowner/contentowner.component';


export const routes: Routes = [
  { path: 'signin', component: SigninComponent },
  { path: 'users', component: UsersListComponent },
  { path: 'users/disabled', component: UsersDisabledComponent },
  { path: 'organizations', component: OrganizationsComponent },
  { path: 'ugvs/:id/requests', component: UgvRequestsComponent },
  { path: 'ugvs', component: UgvComponent },
  { path: 'contentowners', component: ContentownerComponent },
]
