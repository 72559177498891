export class ExternalUser {
  name: string;
  realName: string;
  url: string;
  type: string;

  constructor(values, type) {
    Object.assign(this, values);
    this.type = type;
  }
}
