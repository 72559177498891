import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ContentOwner} from '../models/contentowner';
import {Observable} from 'rxjs/Rx';
import {environment} from '../../../environments/environment';
import {map, flatMap} from 'rxjs/internal/operators';
import {forkJoin} from 'rxjs/index';
import {ExternalUser} from '../models/externaluser';

@Injectable()
export class ContentOwnerService {

  constructor(
    private http: HttpClient,
  ) { }

  getAll(): Observable<ContentOwner[]> {
    const url = environment.api + '/contentOwners';
    return this.http.get(url).pipe(
      map(response => response['_embedded']['contentOwners'] ? response['_embedded']['contentOwners'].map(v => new ContentOwner(v)) : []),
      flatMap((contentOwners: ContentOwner[]) => forkJoin(contentOwners.map(contentOwner => this.setExternalUsers(contentOwner))))
    );
  }

  delete(id: string) {
    const url = environment.api + '/contentOwners/' + id;
    return this.http.delete(url);
  }

  private setExternalUsers(contentOwner: ContentOwner): Observable<ContentOwner> {
    const url = `${environment.api}/contentOwners/${contentOwner.id}/externalUsers`;
    return this.http.get(url).pipe(
      map(response => {
        const googleUsers: ExternalUser[] = response['_embedded']['googleUsers'] ? response['_embedded']['googleUsers']
          .map(v => new ExternalUser(v, 'Google')) : [];
        const youtubeChanneles: ExternalUser[] = response['_embedded']['youTubeChannels'] ? response['_embedded']['youTubeChannels']
          .map(v => new ExternalUser(v, 'YouTube')) : [];
        const facebookUsers: ExternalUser[] = response['_embedded']['facebookUsers'] ? response['_embedded']['facebookUsers']
          .map(v => new ExternalUser(v, 'Facebook')) : [];
        const facebookPages: ExternalUser[] = response['_embedded']['facebookPages'] ? response['_embedded']['facebookPages']
          .map(v => new ExternalUser(v, 'Facebook')) : [];
        const twitterUsers: ExternalUser[] = response['_embedded']['twitterUsers'] ? response['_embedded']['twitterUsers']
          .map(v => new ExternalUser(v, 'Twitter')) : [];
        return [...googleUsers, ...youtubeChanneles, ...facebookPages, ...facebookUsers, ...twitterUsers];
      }),
      map(users => {
        contentOwner.externalUsers = users;
        return contentOwner;
      })
    );
  }
}
