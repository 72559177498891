import {Organization} from './organization';

export class InVIDUser {
  id: string;
  realUsername: string;
  email: string;
  description: string;
  gravatarCode: string;
  authorities: { authority: string }[];
  password: string;
  accountNonExpired: boolean;
  accountNonLocked: boolean;
  credentialsNonExpired: boolean;
  enabled: boolean;
  organization: Organization;

  constructor(values) {
    Object.assign(this, values);
  }
}
