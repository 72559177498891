import { Component, OnInit } from '@angular/core';
import {Organization} from '../shared/models/organization';
import {OrganizationService} from '../shared/services/organization.service';
import {faAt} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.css']
})
export class OrganizationsComponent implements OnInit {

  faAt = faAt;
  organizations: Organization[];
  merging: boolean;
  selectedOrganization: Organization;
  filter = '';

  constructor(
    private organizationService: OrganizationService,
  ) { }

  ngOnInit() {
    this.refreshOrganizations();
  }

  refreshOrganizations() {
    this.organizationService.getOrganizations().subscribe(
      organizations => this.organizations = organizations,
      err => alert(err)
    );
  }

  startMerge() {
    this.merging = true;
  }

  canMerge() {
    return this.merging && this.selectedOrganization && this.organizations.filter(o => o['active']).length > 0;
  }

  cancelMerge() {
    this.merging = false;
    this.selectedOrganization = null;
    this.organizations.map(o => o['active'] = false);
  }

  merge() {
    this.organizationService.mergeOrganizations(this.selectedOrganization, this.organizations.filter(o => o['active']))
      .subscribe(
        () => {
          this.refreshOrganizations();
          this.cancelMerge();
        },
        err => alert('Something went wrong')
      );
  }

  selectOrganization(id: string) {
    var o = this.organizations.find(o => o.id == id);
    if(this.merging && !this.selectedOrganization) {
      this.selectedOrganization = o;
      this.selectedOrganization['active'] = false;
    } else if(this.merging) {
      o['active'] = o['active'] !== undefined ? !o['active'] : true;
    }
  }

  filtered() {
    return this.organizations.filter(o => (!this.selectedOrganization || o.id != this.selectedOrganization.id) && o.name.toLowerCase().includes(this.filter.toLowerCase()));
  }
}
