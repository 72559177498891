import { Component, OnInit } from '@angular/core';
import {ContentOwnerService} from '../shared/services/content-owner.service';
import {ContentOwner} from '../shared/models/contentowner';
import {ExternalUser} from '../shared/models/externaluser';
import {faFacebookSquare, faGoogle, faTwitterSquare, faYoutube} from '@fortawesome/free-brands-svg-icons';
import {faTrash} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-contentowner',
  templateUrl: './contentowner.component.html',
  styleUrls: ['./contentowner.component.css']
})
export class ContentownerComponent implements OnInit {

  faTrash = faTrash;
  contentOwners: ContentOwner[];

  constructor(
    private contentOwnerService: ContentOwnerService,
  ) { }

  ngOnInit() {
    this.contentOwnerService.getAll().subscribe(
      contentOwners => this.contentOwners = contentOwners,
      error1 => alert('Something went wrong: ' + error1)
    );
  }

  getIconName(extUser: ExternalUser) {
    switch (extUser.type) {
      case 'Facebook':
        return faFacebookSquare;
      case 'Twitter':
        return faTwitterSquare;
      case 'YouTube':
        return faYoutube;
      case 'Google':
        return faGoogle;
    }
  }

  openLink(url: string) {
    window.open(url, "_blank");
  }

  deleteContentOwner(contentOwnerId: string) {
    this.contentOwnerService.delete(contentOwnerId)
      .subscribe(
        () => this.contentOwners = this.contentOwners.filter(value => value.id != contentOwnerId),
        err => alert('Something went wrong: ' + err)
      );
  }
}
