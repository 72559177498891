import {InVIDUser} from './invid-user';
import {UGV} from './ugv';

export class ReuseRequest {
  id: string;
  lastStatusChange: Date;
  createdBy: InVIDUser;
  status: Status;
  aboutVideo: UGV;


  constructor(values) {
    if(values["lastStatusChange"])
      this.lastStatusChange = new Date(values["lastStatusChange"]);
    Object.assign(this, values);
  }

}

enum Status {OWNER, JOURNALIST, REJECTED, ACCEPTED}
