import { Component, OnInit } from '@angular/core';
import {InVIDUser} from '../../shared/models/invid-user';
import {InVIDUserService} from '../../shared/services/invid-user.service';
import {faAt, faBuilding, faEnvelope} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-users-disabled',
  templateUrl: './users-disabled.component.html',
  styleUrls: ['./users-disabled.component.css']
})
export class UsersDisabledComponent implements OnInit {
  faAt = faAt;
  faBuilding = faBuilding;
  faEnvelope = faEnvelope;

  users: InVIDUser[];

  constructor(
    private usersService: InVIDUserService
  ) { }

  ngOnInit() {
    this.usersService.getDisabledUsers().subscribe(
      users => this.users = users,
      err => alert(err)
    );
  }

  enableUser(id: string) {
    this.usersService.enableUser(id).subscribe(
      () => this.users = this.users.filter(user => user.id != id),
      err => alert('Impossible to enable this user')
    );
  }

  removeUser(id: string) {
    this.usersService.removeUser(id).subscribe(
      () => this.users = this.users.filter(user => user.id != id),
      err => alert('Impossible to remove this user')
    );
  }

}
