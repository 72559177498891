import {ExternalUser} from './externaluser';
import {InVIDUser} from './invid-user';

export class UGV {
  id: string;
  title: string;
  attribution: string;
  publishDate: Date;
  url: string;
  thumbnail: string;
  description: string;
  lastUpdateDate: Date;
  user: ExternalUser;
  submitters: InVIDUser[];
  reuseRequestsCount: number;

  constructor(values) {
    if(values["publishDate"])
      this.publishDate = new Date(values["publishDate"]);
    if(values["lastUpdateDate"])
      this.publishDate = new Date(values["lastUpdateDate"]);
    Object.assign(this, values);
  }

}
