import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  signinForm: FormGroup;

  constructor(
    private signinService: AuthService,
    private formBuilder: FormBuilder
  ) {
    this.signinForm = this.formBuilder.group({
      email: ['', Validators.email],
      password: ['', Validators.required],
    });
  }

  ngOnInit() {
  }

  login() {
    this.signinService.attemptAuth({
      credentials: this.signinForm.value
    }).subscribe(
      () => this.signinService.redirect(),
      (e) => alert('Invalid email or password')
    );
  }
}
