import {Component, OnInit} from '@angular/core';
import {UgvService} from '../../shared/services/ugv.service';
import {ReuseRequest} from '../../shared/models/reuserequest';
import {ActivatedRoute} from '@angular/router';
import {RequestService} from '../../shared/services/request.service';
import {faTrash} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-ugv-requests',
  templateUrl: './ugvrequests.component.html',
  styleUrls: ['./ugvrequests.component.css']
})
export class UgvRequestsComponent implements OnInit {

  faTrash = faTrash;
  requests: ReuseRequest[];
  public id: string;

  constructor(
    private requestService: RequestService,
    private route: ActivatedRoute,
  ) {
    this.id = this.route.snapshot.params.id;
  }

  ngOnInit() {
    this.requestService.getRequestsByUGVId(this.id).subscribe(
      req => this.requests = req,
      err => alert('Something went wrong: ' + err)
    );
  }

  deleteRequest(id: string) {
    this.requestService.remove(id).subscribe(
      () => this.requests = this.requests.filter(value => value.id != id),
      err => alert('Something went wrong: ' + err)
    );
  }

}
