import { Component, OnInit } from '@angular/core';
import {AuthService} from '../auth/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './app-navbar.component.html',
  styleUrls: ['./app-navbar.component.css']
})
export class AppNavbarComponent implements OnInit {
  isNavbarCollapsed = true;
  username: string;

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit() {
    if(localStorage.getItem('auth')){
      this.username = JSON.parse(localStorage.getItem('auth')).name;
    }
    this.authService.auth.subscribe(auth => this.username = auth.username);
    console.log(this.username);
  }

  logout() {
    localStorage.removeItem('auth');
    this.username = null;
  }

}
