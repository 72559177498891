import {ExternalUser} from './externaluser';

export class ContentOwner {
  id: string;
  username: string;
  name: string;
  email: string;
  externalUsers: ExternalUser[];

  constructor(values) {
    Object.assign(this, values);
  }
}
