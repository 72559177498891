import { Component, OnInit } from '@angular/core';
import {InVIDUserService} from '../../shared/services/invid-user.service';
import {InVIDUser} from '../../shared/models/invid-user';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit {

  users: InVIDUser[];

  constructor(
    private usersService: InVIDUserService
  ) { }

  ngOnInit() {
    this.usersService.getUsersList().subscribe(
      users => this.users = users,
      err => alert(err)
    );
  }

}
