import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import formurlencoded from 'form-urlencoded';
import {tokenNotExpired} from 'angular2-jwt';

import { environment } from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {OAuthResponse} from '../shared/models/oauth-response';
import {Subject} from 'rxjs/Rx';

/**
 * Local login (using email and password).
 * Everything regarding Local login must go in here!
 *
 * Supports:
 * - Sign in.
 *
 * Notes:
 * The API doesn't support connecting Local accounts with any other login. Trying to connect
 * any account with local login will result in a new sign in.
 * Local logins are only used by a specific role of user "ROLE_INVID". Make sure you distinct
 * these from other roles to avoid user trying to connect accounts from this type of user.
 */
@Injectable()
export class AuthService {

  auth: Subject<OAuthResponse> = new Subject<OAuthResponse>();

  constructor(
    private router: Router,
    private http: HttpClient,
  ) {
  }

  /**
   * Attempt login. Expecting a token in return.
   * Note: Auth local ignores purge setting due to being the only one of its kind (meaning, that it
   * is impossible for a local account to connect with any other account).
   */
  attemptAuth({ credentials, redirectPath = '/' }): Observable<void> {
    const { email, password } = credentials;

    window.sessionStorage.setItem('auth_redirect_path', redirectPath);

    const body = {
      grant_type: 'password',
      client_id: environment.local.clientId,
      client_secret: environment.local.clientSecret,
      username: email,
      password: password,
    };
    const encoded = formurlencoded(body);
    const headers = { headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'}) };
    return this.http.post<OAuthResponse>(`${environment.apiIdentityProvider}/oauth/token`, encoded, headers).pipe(
      map(auth => {
        if(auth.roles.includes('ROLE_ADMIN')){
          this.writeUserToPersistentStorage(auth);
          this.auth.next(auth);
        }
        else
          throw new Error("This is not an Admin account");
      })
    )
  }

  private writeUserToPersistentStorage(auth: OAuthResponse) {
    const authStorage = {
      token: auth.access_token,
      expiresAt: auth.expires_in * 1000, // UNIX time.
      name: auth.username
    };
    window.localStorage.setItem('auth', JSON.stringify(authStorage));
  }

  redirect() {
    const redirectPath = window.sessionStorage.getItem('auth_redirect_path');
    if (redirectPath) {
      this.router.navigate([redirectPath]);
    }
    window.sessionStorage.removeItem('auth_redirect_path');
  }

  public getToken(): string {
    return JSON.parse(localStorage.getItem('auth')).token;
  }

  public isAuthenticated(): boolean {
    // get the token
    const token = this.getToken();
    // return a boolean reflecting
    // whether or not the token is expired
    return tokenNotExpired(null, token);
  }
}

