import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {InVIDUser} from '../models/invid-user';
import {Organization} from '../models/organization';
import {map, flatMap} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/internal/operators';

@Injectable()
export class InVIDUserService {
  constructor(
    private http: HttpClient
  ){}

  getDisabledUsers() {
    const url = environment.apiIdentityProvider + '/inVIDUsers/search/findByEnabledFalse';
    return this.http.get(url).pipe(
      map (response => response['_embedded']['inVIDUsers'] || []),
      flatMap((users: InVIDUser[]) => {
        const users$: Observable<InVIDUser>[] = users.map(user => this.fullUser(user));
        return forkJoin(...users$);
      }),
    )
  }


  getUsersList() {
    const url = environment.apiIdentityProvider + '/inVIDUsers';
    return this.http.get(url).pipe(
      map (response => response['_embedded']['inVIDUsers'] || []),
      flatMap((users: InVIDUser[]) => {
        const users$: Observable<InVIDUser>[] = users.map(user => this.fullUser(user));
        return forkJoin(...users$);
      }),
    )
  }

  enableUser(id: string) {
    const url = `${environment.apiIdentityProvider}/inVIDUsers/${id}`;
    return this.http.patch(url, {enabled: true});
  }

  removeUser(id: string) {
    const url = `${environment.apiIdentityProvider}/inVIDUsers/${id}`;
    return this.http.delete(url);
  }

  private fullUser(user: InVIDUser): Observable<InVIDUser> {
      const organizationUrl = `${environment.apiIdentityProvider}/inVIDUsers/${user.id}/organization`;
      return this.http.get<Organization>(organizationUrl).pipe(
        map((organization: Organization) => {
          user.organization = organization;
          return user;
        }),
        catchError(() => of(user))
      );
  }
}
