export const environment = {
  production: true,

  // Endpoints.
  api: 'https://rights-api.invid.udl.cat',
  apiIdentityProvider: 'https://identity-provider.invid.udl.cat',

  // Analytics (type Google) -> Change index.html & CoreModule to switch to another provider.
  // https://developers.google.com/analytics/devguides/collection/analyticsjs/
  analytics: {
    trackingId: 'UA-90711710-1',
    cookieDomain: 'auto',
  },

  // OAuth/OpenID credentials.
  local: {
    clientId: 'client',
    clientSecret: 'clientpassword',
  },
  facebook: {
    redirectUri: `${window.location.origin}/signin/callback/facebook`,
    clientId: '355107811549739',
    clientSecret: '',
    scope: [
      'public_profile',
      'email',
      'user_events',
      'pages_show_list',
    ].join(' '),
  },
  google: {
    redirectUri: `${window.location.origin}/signin/callback/google`,
    clientId: '718948614796-imc4g1uij9dlj6ul1hicfo25sl9vlofa.apps.googleusercontent.com',
    clientSecret: '',
    scope: [
      'profile',
      'email',
    ].join(' '),
  },
  twitter: {
    redirectUri: `${window.location.origin}/signin/callback/twitter`,
    clientId: '', // Client ID is only used by the server for Twitter OAuth1.
    clientSecret: '',
    scope: '',
  },
  youtube: {
    redirectUri: `${window.location.origin}/signin/callback/youtube`,
    clientId: '718948614796-imc4g1uij9dlj6ul1hicfo25sl9vlofa.apps.googleusercontent.com',
    clientSecret: '',
    scope: [
      'profile',
      'email',
      'https://www.googleapis.com/auth/youtube.readonly',
    ].join(' '),
  },
};
