import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Organization} from '../models/organization';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {InVIDUser} from '../models/invid-user';
import {forkJoin, Observable} from 'rxjs/index';


@Injectable()
export class OrganizationService {
  constructor(
    private http: HttpClient
  ){}

  mergeOrganizations(organizationToKeep: Organization, organizationsToMerge: Organization[]) {
    const url = `${environment.apiIdentityProvider}/organizations/${organizationToKeep.id}/merge`;
    return this.http.post(url, {organizationIds: organizationsToMerge.map(o => o.id)});
  }

  getOrganizations() {
    const url = environment.apiIdentityProvider + '/organizations';
    return this.http.get(url).pipe(
      map (response => response['_embedded']['organizations'] || []),
      map((organizations: any[]) => organizations.map(o => new Organization(o)))
    );
  }
}
